import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'


export const HomepageTemplate = ({ data }) => {
  const homepage = data.prismicHomepage || {}
  const sidebar = data.prismicLayout || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []

  // console.log("homepage", homepage)

  return (
    <Layout
      metadata={{
        title: homepage.data.title.text,
        description: homepage.data.description.text
      }}
      sidebar={sidebar.data}
      activeDocMeta={{
        lang,
        type,
        url,
        alternateLanguages,
      }}
    >
      <SliceZone slices={data.prismicHomepage.data.body} />
    </Layout>
  )
}

export const query = graphql`
query homepageQuery($lang: String) {
  prismicHomepage(lang: {eq: $lang}) {
    alternate_languages {
      uid
      type
      lang
      url
    }
    lang
    url
    type
    data {
      title {
        text
      }
      description {
        text
      }
      no_index
      body {
        ... on PrismicHomepageBodyCta {
          slice_type
          primary {
            container_center
            heading {
              text
            }
            link_button {
              url
            }
            text {
              text
            }
            text_button {
              text
            }
          }
          slice_type
        }
        ... on PrismicHomepageBodyTextImage {
          slice_type
          primary {
            order
            image {
              url
              alt
            }
            text {
              raw
            }
          }
        }
        ... on PrismicHomepageBodyFullWidthImage {
          slice_type
          primary {
            image {
              url
              alt
            }
            link {
              url
            }
          }
        }
        ... on PrismicHomepageBodyImageGrid {
          slice_type
          primary {
            type
            desktop
            mobile
            gap
          }
          items {
            image {
              url
              alt
            }
          }
        }
        ... on PrismicHomepageBodyRichText {  
          slice_type        
          primary {
            container_center
            text_align
            content {
              raw
            }
          }
        }
        ... on PrismicHomepageBodyEmbedVideo {  
          slice_type        
          primary {
            link {
              url
            }
          }
        }
      }
    }
  }
  prismicLayout(lang: {eq: $lang}) {
    ...LayoutFragment
   }
}
`

export default withPreview(HomepageTemplate)
